<div class="modal-header">
    <div class="modal-title pull-left h4"><fa-icon class="text-info" icon="question-circle"></fa-icon> {{ help.title }}</div>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="modal-text">
      <div *ngIf="help.terms">
        <dl>
          <div *ngFor="let term of help.terms">
            <dt>{{ term.term }}</dt>
            <dd>{{ term.definition }}</dd>
          </div>
        </dl>
      </div>
      <div *ngIf="help.message" [innerHTML]="help.message"></div>
    </div>
  </div>
