import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminLayoutComponent } from './containers/admin-layout/admin-layout.component';
import { SiteLayoutComponent } from './containers/site-layout/site-layout.component';
import { AuthCallbackComponent } from './core/auth/auth-callback.component';
import { AuthGuard } from 'src/app/core/common/guards/auth.guard';
import { UnauthorizedComponent } from 'src/app/containers/unauthorized/unauthorized.component';
import { CloseAppComponent } from './views/close-app/close-app.component';

export const routes: Routes = [
    {
        path: 'auth-callback',
        component: AuthCallbackComponent
    },
    {
        path: '',
        component: SiteLayoutComponent,
        loadChildren: () => import('./views/site/site.module').then(m => m.SiteModule)
    },
    {
        path: 'admin',
        component: AdminLayoutComponent,
        loadChildren: () => import('./views/admin/admin.module').then(m => m.AdminModule),
        canActivate: [ AuthGuard ]
    },
    {
        path: 'unauthorized',
        component: UnauthorizedComponent
    },
    {
        path: 'close',
        component: CloseAppComponent
    }
]

@NgModule({
    imports: [ RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload' }) ],
    exports: [ RouterModule ]
  })
  export class AppRoutingModule {}