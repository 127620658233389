
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { Observable, Subject } from 'rxjs';
import { UserManager, User } from 'oidc-client';

@Injectable()
export class AuthService {
  private subject = new Subject<User>();
  private user: User = null;
  private _redirectUrl: string;
  userManager = new UserManager(environment.userManagerSettings);
  loggedIn: boolean = false;
  

  constructor() {
    this.userManager.getUser()
        .then(user => {
          if (user) {
            this.loggedIn = true;
            this.user = user;
          } else {
            this.loggedIn = false;
          }
          this.subject.next(this.user);
        })
        .catch(error => {
          this.loggedIn = false;
          console.log('getUser error', error.error);
          console.log('error.Id', error.error.Id);
        });
       
    // this.userManager.signinRedirectCallback()
    //   .then(function (user) {
    //     console.log('user');
    // }).catch(error => {
    //   console.log('error', error)
    // });

    this.userManager.events.addUserUnloaded(() => {
      this.user = null;
      this.loggedIn = false;
      // window.sessionStorage.clear();
      this.subject.next(this.user);
    });

   this.userManager.events.addAccessTokenExpired(() => {
      this.user = null;
      this.subject.next(this.user);
      this.userManager.signoutRedirect().then(function() {
        }).catch(function(error) {
          console.log('signoutRedirect error', error.error);
          console.log('error.Id', error.error.Id);
        });
    });
  }

  get redirectUrl(): string {
    let url = '';
    // window.sessionStorage.clear();
    if (window.sessionStorage && window.sessionStorage.getItem('redirectUrl')) {
      url = window.sessionStorage.getItem('redirectUrl');
    }

    return decodeURIComponent(url);
  }

  set redirectUrl(value: string) {
    if (window.sessionStorage) {
      window.sessionStorage.setItem('redirectUrl', decodeURIComponent(value));
    }
  }

  isLoggedIn(): boolean {
    return this.user != null && !this.user.expired;
  }

  getAuthorizationHeaderValue(): string {
    return `${this.user.token_type} ${this.user.access_token}`;
  }

  startAuthentication(): Promise<void> {
    return this.userManager.signinRedirect();
  }

  completeAuthentication(): Promise<void> {
    return this.userManager.signinRedirectCallback().then(user => {
        this.user = user;
        if (user.profile.role == 'Atrak User') {
          let url = encodeURI(`http://www.advantage-logistics.net/Package_Tracking/Login.do?uid=${user.profile.username.trim()}&password=${user.profile.password.trim()}`);
          window.open(url);
        }
    }).catch(function (error) {
      console.log('completeAuthentication error1', error);
      console.log('completeAuthentication error2', error.error);
      console.log('error.Id', error.error.Id);
    })
  }

  startSignOut(): Promise<void> {
    return this.userManager.signoutRedirect();
  }

  completeSignOut(): Promise<void> {
    return this.userManager.signoutRedirectCallback().then(() => {
      this.user = null;
    });
  }

  getUserChange(): Observable<User> {
    return this.subject.asObservable();
  }

  getUser(): User {
    return this.user;
  }
}
