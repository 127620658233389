<div class="modal-header">
    <div class="modal-title pull-left h4"><fa-icon class="text-info" icon="camera"></fa-icon> Camera</div>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="form-row">
        <div class="form-group col-md-8">
            <select id="sources" class="form-control"></select>
        </div>
    </div>
    <div [hidden]="image" id="dwtcontrolContainer"></div>
</div>
<div class="modal-footer">
    <button [disabled]="checkIfImagesInBuffer()" type="button" 
        class="btn btn-primary right"
        (click)="CaptureImage()">
        <fa-icon icon="camera"></fa-icon>
        Capture
    </button> 
    <button class="btn btn-secondary" (click)="action(false)">Cancel</button>
</div>
