import { Injectable } from '@angular/core';
import { Subject }    from 'rxjs';
import { AdminHeader } from '../models/admin-header.model';

@Injectable()
export class AdminHeaderService {
    private adminHeaderChange = new Subject<AdminHeader>();
    private minimizeChange = new Subject<boolean>();
    private childMinimizeChange = new Subject<boolean>();

    adminHeaderChange$ = this.adminHeaderChange.asObservable();
    minimizeChange$ = this.minimizeChange.asObservable();
    childMinimizeChange$ = this.childMinimizeChange.asObservable();

    setAdminHeader(adminHeader: AdminHeader): void {
        this.adminHeaderChange.next(adminHeader);
    }

    changeMinimize(minimize: boolean): void {
        this.minimizeChange.next(minimize);
    }

    changeChildMinimize(minimize: boolean): void {
        this.childMinimizeChange.next(minimize);
    }
}