import { Component, OnInit, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-close-app',
  templateUrl: './close-app.component.html',
  styleUrls: ['./close-app.component.css']
})
export class CloseAppComponent implements OnInit, AfterViewInit {

  constructor() { }

  ngOnInit(): void {
    
  }

  onClose(): void {
    navigator['app'].exitApp();
  }

  ngAfterViewInit(): void {
    // console.log('close me')
    // open(`${location.host}/close`, '_self').close();
  }
}
