import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AgmCoreModule } from '@agm/core';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { ModalModule, BsModalRef } from 'ngx-bootstrap/modal';
import { ToastrModule } from 'ngx-toastr';
import { AuthService } from './core/auth/auth.service';
import { AuthGuard } from 'src/app/core/common/guards/auth.guard';
import { UnsavedGuard } from 'src/app/core/common/guards/unsaved.guard';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

//Interceptors
import { JwtInterceptor } from './core/common/interceptors/jwt.interceptor';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routing';
import { AdminLayoutComponent } from './containers/admin-layout/admin-layout.component';
import { SiteLayoutComponent } from './containers/site-layout/site-layout.component';

const APP_CONTAINERS = [
  AdminLayoutComponent,
  SiteLayoutComponent
]

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';


import { AuthCallbackComponent } from './core/auth/auth-callback.component';
import { UnauthorizedComponent } from './containers/unauthorized/unauthorized.component';
import { AlertModule } from 'ngx-bootstrap/alert';
import { ModalsModule } from './core/common/modals/modals.module';
import { CloseAppComponent } from './views/close-app/close-app.component';

@NgModule({
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    SiteLayoutComponent,
    APP_CONTAINERS,
    AuthCallbackComponent,
    UnauthorizedComponent,
    CloseAppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    CollapseModule.forRoot(),
    PerfectScrollbarModule,
    FontAwesomeModule,
    ModalModule.forRoot(),
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ModalsModule,
    ToastrModule.forRoot({
      timeOut: 6000,
      positionClass: 'toast-bottom-center',
      preventDuplicates: true,
    }),
    AlertModule.forRoot(),
    ChartsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCtv-N9tuh0uOvJy214EVfhmquOgfFjVQs'
    })
  ],
  providers: [
    BsModalRef,
    AuthGuard,
    UnsavedGuard,
    AuthService,
    {
      provide: LocationStrategy,
      useClass: PathLocationStrategy
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    }
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
