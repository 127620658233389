import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd  } from '@angular/router';
import { Subscription } from 'rxjs';

import { AuthService } from '../../core/auth/auth.service';
import { User as OidcUser } from 'oidc-client';
import { User } from 'src/app/core/user/user.model';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/core/user/user.service';

@Component({
  selector: 'app-site-layout',
  templateUrl: './site-layout.component.html',
  styleUrls: ['./site-layout.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class SiteLayoutComponent implements OnInit, OnDestroy {
  oidcUser: OidcUser = null;
  user: User;
  subscription: Subscription;
  loading = false;
  showFooter = false;
  navbarCollapsed = false;
  copyrightMessage = `© ${new Date().getFullYear()} ADL Delivery - All Rights Reserved`;

  constructor(private authService: AuthService,
    private userService: UserService,
    private router: Router) { 
      this.oidcUser = this.authService.getUser();

      this.subscription = this.authService
                                .getUserChange()
                                .subscribe(oidcUser => {
                                  if (oidcUser) {
                                    this.oidcUser = oidcUser;
                                  }
                                });
      
      // if (this.oidcUser && !this.user)
      //   this.getUser(+this.oidcUser.profile.user_id);
      // else
      //   this.subscription = this.authService
      //                           .getUserChange()
      //                           .subscribe(oidcUser => {
      //                             if (oidcUser) {
      //                               this.oidcUser = oidcUser;
      //                               this.getUser(+oidcUser.profile.user_id);
      //                             }
      //                           });
  }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
          return;
      }
      window.scrollTo(0, 0);
    });
  }

  getUser(id: number): void {
    this.userService.get(id)
        .subscribe(user => {
          this.user = user;
        });
  }

  logIn() {
    // window.location.href = 'http://advantage-logistics.net/Package_Tracking/';
    this.authService
        .startAuthentication();
    // if (environment.production) {
    //   window.location.href = 'http://advantage-logistics.net/Package_Tracking/';
    // } else {
    //   this.authService
    //     .startAuthentication();
    // }
  }

  logOut() {
    this.authService
        .startSignOut();
  }

  redirect(): void {
    console.log(location.href)
    location.replace(`${location.protocol}//${location.host}/admin/contractors/manage`)
  }

  ngOnDestroy() {
    if (this.subscription)
      this.subscription.unsubscribe();
  }
}
