import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-cropper-modal',
  templateUrl: './cropper-modal.component.html',
  styleUrls: ['./cropper-modal.component.css']
})
export class CropperModalComponent implements OnInit {
  @Input() image = '';
  croppedImage = '';
  subject: Subject<string>;

  constructor(public modalRef: BsModalRef) { }

  ngOnInit(): void {
  }

  imageCropped(event: ImageCroppedEvent) {
      this.croppedImage = event.base64;
  }

  action(value: boolean) {
    if (value) {
      this.subject.next(this.croppedImage);
    } else
      this.subject.next(null);
    
    this.modalRef.hide();
    this.subject.complete();
  }
}
