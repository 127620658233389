import { Component, OnInit } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';

import { AuthService } from './auth.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-auth-callback',
  template: '<div></div>'
})
export class AuthCallbackComponent implements OnInit {

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit() {
      this.authService.completeAuthentication()
          .then((data) => {
            let user = this.authService.getUser();
            if (user != null && user.profile.role != 'Atrak User' && user.profile.role != 'Driver') {
              if (this.authService.redirectUrl) {
                this.router.navigateByUrl(decodeURIComponent(`${this.authService.redirectUrl}`));
              } else {
                this.router.navigate(['/admin/contractors/manage']);
              }
            } else {
              // this.authService.startSignOut();
              this.router.navigate(['/']);
            }
          });
  }
}
