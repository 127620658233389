import { Component } from '@angular/core';

@Component({
  selector: 'body',
  template: `<router-outlet></router-outlet>`
})
export class AppComponent {
  constructor() { 
  }
}
