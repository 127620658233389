import { Component, OnInit, Input } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Help } from '../../models/help.model';

@Component({
  selector: 'app-help-modal',
  templateUrl: './help-modal.component.html',
  styleUrls: ['./help-modal.component.css']
})
export class HelpModalComponent implements OnInit {
  @Input() help: Help;
  modalRef: BsModalRef;

  constructor(modalRef: BsModalRef) { 
    this.modalRef = modalRef;
  }

  ngOnInit(): void {
  }
}
