import { Status } from '../status/status.model';

import { Deserializable } from 'src/app/core/deserializable/deserializable.model';
import { Location2 } from '../locations/location2-model';
import { BusinessEntity } from '../business-entity/business-entity.model';
import { Department } from '../department/department.model';

export class User implements Deserializable {
    id: number = null;
    username: string = null;
    firstName: string = null;
    lastName: string = null;
    image: string = null;
    statusId: number = null;
    userTypeId: number = null;
    departmentId: number = null;
    roleId: number = null;
    phone: string = null;
    email: string = null;
    businessEntityId: number = null;
    locationIds: number[] = null;
    status: Status = null;
    locations: Location2[] = null;
    businessEntity: BusinessEntity = null;
    department: Department = null;

    deserialize(input: any): this {
        return Object.assign(this, input);
    }
}