import { Component, OnInit, OnDestroy, AfterViewChecked, ChangeDetectorRef, Inject, ViewEncapsulation } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Subscription } from 'rxjs';
import { AuthService } from '../../core/auth/auth.service';
import { User as OidcUser } from 'oidc-client';
import { User } from 'src/app/core/user/user.model';
import { NavData, navItems } from '../../_nav';
import { AdminHeaderService } from 'src/app/core/common/services/admin-header.service';
import { Submenu } from 'src/app/core/common/models/submenu.model';
import { Breadcrumb } from 'src/app/core/common/models/breadcrumb.model';
import { Menu } from 'src/app/core/common/models/menu.model';
import { AdminHeader } from 'src/app/core/common/models/admin-header.model';
import { UserService } from 'src/app/core/user/user.service';
import { ItemService } from 'src/app/core/item/item.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-dashboard',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.css'],
  providers: [ 
    AdminHeaderService
  ]
})
export class AdminLayoutComponent implements OnInit, AfterViewChecked, OnDestroy {
  subscription: Subscription;
  oidcUser: OidcUser = null;
  user: User;
  breadcrumbs: Breadcrumb[] = [];
  selectedSubMenu: Menu;
  adminHeader: AdminHeader;
  public navItems: NavData[] = null;
  public sidebarMinimized = true;
  private changes: MutationObserver;
  public element: HTMLElement;
  minimize = false;
  isValid = true;

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private adminHeaderService: AdminHeaderService,
    private cdRef: ChangeDetectorRef,
    private router: Router,
    @Inject(DOCUMENT) _document?: any) {
    this.router.events.subscribe(val => {
      if (val instanceof NavigationEnd)
        this.updateNav(val.url);
    });

    this.oidcUser = this.authService.getUser();
    this.subscription = this.authService
                            .getUserChange()
                            .subscribe(oidcUser => {
                              if (oidcUser) {
                                this.oidcUser = oidcUser;
                                this.setNav();
                                this.getUser(+oidcUser.profile.user_id);
                              }
                            });


    this.changes = new MutationObserver((mutations) => {
      this.sidebarMinimized = _document.body.classList.contains('sidebar-minimized');
    });

    this.element = _document.body;
    this.changes.observe(<Element>this.element, {
      attributes: true,
      attributeFilter: ['class']
    });

    this.adminHeaderService.adminHeaderChange$
      .subscribe(adminHeader => {
        this.adminHeader = adminHeader;

        if (adminHeader.submenu) {
          if (adminHeader.submenu.dropdown) {
            adminHeader.submenu.dropdown.sort((a, b): number => {
              return a.name < b.name ? -1 : 1;
            });

            this.selectedSubMenu = adminHeader.submenu.dropdown.find(s => s.selected);
            this.adminHeader.submenu.dropdown = this.adminHeader.submenu.dropdown.filter(s => !s.selected);
          }
        }
      });

    this.adminHeaderService.childMinimizeChange$
      .subscribe(minimize => this.minimize = minimize);

      if (this.oidcUser) {
        this.setNav();
        this.getUser(+this.oidcUser.profile.user_id);
      }
  }

  ngOnInit() {
  }

  getUser(id: number): void {
    this.userService.get(id)
        .subscribe(user => {
          this.user = user;
        });
  }

  setNav(): void {
    let filterItems: NavData[] = [];
    let role = this.oidcUser.profile.role;
    for (let item of navItems) {
      if (!item.roles || item.roles.indexOf(role) != -1)
        filterItems.push(item);
    }

    this.navItems = filterItems;
  }

  updateNav(url: string): void {
    if (url.indexOf('account') != -1) {
      this.updateNavUrl('Account');
    } else if (url.indexOf('shipment') != -1) {
      this.updateNavUrl('Shipment');
    } else if (url.indexOf('contractor') != -1) {
      this.updateNavUrl('Contractor');
    } else if (url.indexOf('customer') != -1) {
      this.updateNavUrl('Customer');
    } else if (url.indexOf('user') != -1) {
      this.updateNavUrl('User');
    }
  }

  updateNavUrl(name: string): void {
    if (name) {
      let navItem = this.navItems.find(n => n.name == name);
      
      if (navItem && navItem.url === ' ')
        navItem.url = ''; 
      
        for (let nav of this.navItems.filter(n => n.name != name))
        if (nav.url === '')
          nav.url = ' ';
    }
  }

  ngAfterViewChecked(): void {
    this.cdRef.detectChanges();
  }

  onMinimize(): void {
    this.minimize = !this.minimize;
    this.adminHeaderService.changeMinimize(this.minimize);
  }

  logOut() {
    this.authService
        .startSignOut();
  }

  ngOnDestroy(): void {
    this.changes.disconnect();
    this.subscription.unsubscribe();
  }
}
