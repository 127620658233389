// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  apiUrl: 'https://api2.adldelivery.com/api',
  reportViewUrl: '',
  smartyStreetsKey: '8288206142130698',
  dynamsoftKey: 'f0068WQAAAI5/YEh35BtNdfYqKzXs4IhDzpSDg5dm0YqSWcxY78U2zoMBvFg8X843o0JGHr+urtxffrwy5PrSHboo5yztiaM=',
  dynamsoft: {
    twainKey: 'f0068WQAAAI5/YEh35BtNdfYqKzXs4IhDzpSDg5dm0YqSWcxY78U2zoMBvFg8X843o0JGHr+urtxffrwy5PrSHboo5yztiaM=',
    cameraKey: 'f00889QAAAGlsekTiGXXEKrI2ik6/kFAkAUQcmQebcZTqQobStyAQq/Rbetz7LoLplXyopXtff47PZoipSEQwPygJKllm3stIP9iYFJB+NGBubApExuYAISAwcw=='
  },
  vehicleApiUrl: 'https://vpic.nhtsa.dot.gov/api/vehicles',
  userManagerSettings: {
    authority: 'https://auth.staging.adldelivery.com',
    client_id: 'adllivesite',
    redirect_uri: 'https://www.adldelivery.com/auth-callback',
    post_logout_redirect_uri: 'https://www.adldelivery.com',
    response_type: 'code',
    response_mode: 'query',
    scope: 'openid profile full_api_scope',
    loadUserInfo: true,
    monitorSession: true,
    checkSessionInterval: 2000,
    revokeAccessTokenOnSignout: true
  }
};
