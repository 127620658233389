<div class="modal-header">
    <div class="modal-title pull-left h4"><fa-icon class="text-warning" icon="exclamation-triangle"></fa-icon> {{ title }}</div>
    <button type="button" class="close pull-right" aria-label="Close" (click)="action(false)">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="modal-text">
      <p>{{ message }}</p>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary right" (click)="action(true)">Yes</button>
    <button class="btn btn-secondary" (click)="action(false)">No</button>
  </div>
