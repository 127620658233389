<!-- <alert type="primary" [dismissible]="true" style="padding-bottom:0">
    <fa-icon icon="road"></fa-icon>&nbsp;<strong>Key West Routes</strong>
    - Due to a lack of freight for the Key West area, effective immediately we will be moving to a <u>Monday, Wednesday and Friday</u> distribution. Thank you for your understanding.
</alert> -->
<div style="background-color:#4f5152">
  <div class="container">
    <nav class="navbar navbar-expand-lg navbar-custom">
      <a class="navbar-brand mx-auto" [routerLink]="['/']" >
        <img src="assets/img/logo.png" alt="ADL Delivery" class="img-fluid">
      </a>
      <!-- <button class="navbar-toggler" 
              type="button" 
              data-toggle="collapse" 
              (click)="navbarCollapsed = !navbarCollapsed" 
              aria-controls="navbarSupportedContent" 
              [attr.aria-expanded]="!navbarCollapsed"
              aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
      </button> -->
      <div class="collapse navbar-collapse"
           [collapse]="navbarCollapsed"
           [isAnimated]="true"
           id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item dropdown"> 
            <a class="nav-link dropdown-toggle" 
              href="#" 
              id="navbarDropdown" 
              role="button" 
              data-toggle="dropdown" 
              aria-haspopup="true" 
              aria-expanded="false"> 
              Services
            </a>
            <div class="dropdown-menu dropdown-menu2" aria-labelledby="navbarDropdown">
              <a class="dropdown-item2" [routerLink]="['/services']">All Services</a> <!--Had this originally: (click)="navbarCollapsed = true" -->
              <a class="dropdown-item2" [routerLink]="['/services/parcel-delivery']">Parcel Delivery</a>
              <a class="dropdown-item2" [routerLink]="['/services/fleet-replacement']">Fleet Replacement</a>
              <a class="dropdown-item2" [routerLink]="['/services/customized-logistics']">Customized Logistics</a>
              <a class="dropdown-item2" [routerLink]="['/services/warehouse-distribution']">Warehouse &amp; Distribution</a>
            </div>
          </li>
          <li class="nav-item"> 
            <a class="nav-link" [routerLink]="['/locations']">Locations</a> 
          </li>
          <li class="nav-item"> 
            <a class="nav-link" [routerLink]="['/careers']">Careers</a> 
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/about']">About</a> 
          </li>
          <li class="nav-item"> 
            <a class="nav-link" [routerLink]="['/contact']">Contact</a> 
          </li>
        </ul>
      </div>
      <form class="form-inline mx-auto">
        <div class="header-btn"> 
          <a href="tel:8774233741" class="border-btn">
            877-423-3741
          </a> 
          <a [routerLink]="['/contact']" class="red-btn">
            Request Info
          </a> 
          <a *ngIf="!oidcUser" href="#" class="black-btn" (click)="logIn()">Login</a>
          <button class="navbar-toggler" 
              type="button" 
              data-toggle="collapse" 
              (click)="navbarCollapsed = !navbarCollapsed" 
              aria-controls="navbarSupportedContent" 
              [attr.aria-expanded]="!navbarCollapsed"
              aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        </div>
        <ul *ngIf="oidcUser" class="nav navbar-nav ml-auto">
          <li class="nav-item dropdown" dropdown placement="bottom right">
            <a class="nav-link" href="#" role="button" aria-haspopup="true" aria-expanded="false" dropdownToggle (click)="false">
              <img *ngIf="user && user.image" class="user" [src]="user.image">
              <fa-icon *ngIf="!user || !user.image" icon="user-circle" size="2x"></fa-icon>
            </a>
            <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
              <a class="dropdown-item" (click)="redirect()" style="cursor: pointer"><i class="fa fa-cog"></i> A-Trak</a>
              <a class="dropdown-item" (click)="logOut()" style="cursor: pointer"><i class="fa fa-lock"></i> Logout</a>
            </div>
          </li>
        </ul>
    </form>
    </nav>
  </div>
</div>

<main role="main">
  <router-outlet></router-outlet>
</main>

<footer class="footer">
  <div class="footer-top">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-6">
          <div class="footer-nav">
            <h4>ADL Services</h4>
            <ul>
              <li><a [routerLink]="['/services/parcel-delivery']">Parcel Delivery</a></li>
              <li><a [routerLink]="['/services/fleet-replacement']">Fleet Replacement</a></li>
              <li><a [routerLink]="['/services/customized-logistics']">Customized Logistics</a></li>
              <li><a [routerLink]="['/services/warehouse-distribution']">Warehousing &amp; Distribution</a></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="footer-nav">
            <h4>Connect with ADL</h4>
            <ul>
                <li><a href="https://www.facebook.com/ADL.Delivery" target="_blank">Facebook</a></li>
              <li><a href="https://twitter.com/adldelivery" target="_blank">Twitter</a></li>
              <li><a href="https://www.youtube.com/user/adldelivery" target="_blank">YouTube</a></li>
              <li><a href="https://www.linkedin.com/company/adl-delivery/about/" target="_blank">LinkedIn</a></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="footer-nav">
            <h4>Contact ADL</h4>
            <ul>
              <li>PO Box 1487 <br />
                Thonotosassa, FL 33592-1487</li>
              <li class="mail-div"><a href="mailto:info@adldelivery.com">info@adldelivery.com</a></li>
              <li class="phone-div"><a href="tel:8774233741">877.423.3741</a></li>
            </ul>
          </div>
        </div>
        <div class="col-lg-3 col-md-6">
          <div class="footer-nav footer-logos">
            <h4>Membership</h4>
            <ul>
              <li>
                <a href="http://www.floridamessenger.org/" target="_blank" >
                  <img src="../../../assets/img/fam-logo.png" alt="" class="img-fluid fam" />
                </a>
              </li>
              <li>
                <a href="https://expresscarriers.org/" target="_blank" >
                  <img src="../../../assets/img/eca-log.png" alt="" class="img-fluid eca" />
                </a>
              </li>
              <li>
                <a href="https://www.clda.org/i4a/pages/index.cfm?pageid=1" target="_blank" >
                  <img src="../../../assets/img/clda-img.png" alt="" class="img-fluid clda" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-bottom">
    <div class="container">
      <div class="footer-link">
        <ul>
          <li><a [routerLink]="['/']">Home</a></li>
          <li><a [routerLink]="['/services']">Services</a></li>
          <li><a [routerLink]="['/locations']">Locations</a></li>
          <li><a [routerLink]="['/careers']">Careers</a></li>
          <li><a [routerLink]="['/about']">About</a></li>
          <li><a [routerLink]="['/contact']">Contact</a></li>
        </ul>
      </div>
      <div class="copyright">{{ copyrightMessage }}</div>
    </div>
  </div>
</footer>
  


