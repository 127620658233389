import { Component, Input } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-error-modal',
  templateUrl: './error-modal.component.html',
  styleUrls: ['./error-modal.component.css']
})
export class ErrorModalComponent {
  @Input() title: string;
  @Input() message: string;
  subject: Subject<boolean>;

  constructor(public modalRef: BsModalRef) { }

  action(value: boolean) {
    this.modalRef.hide();
    this.subject.next(value);
    this.subject.complete();
  }
}
