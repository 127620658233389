import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';
import { WarningModalComponent } from '../modals/warning-modal/warning-modal.component';

export interface IDirty {
    isDirty(): boolean;
}

@Injectable()
export class UnsavedGuard implements CanDeactivate<IDirty> {
    constructor(private modalService: BsModalService) {
    }

    canDeactivate(
        component: IDirty): Observable<boolean> | Promise<boolean> | boolean {
        if (component.isDirty()) {
            const subject = new Subject<boolean>();
            const initialState = { 
                title: 'Leave Without Saving', 
                message: 'Are you sure you want to leave without saving your changes?'};

            const modal = this.modalService.show(WarningModalComponent, {'class': 'modal-dialog-primary', initialState});
            modal.content.subject = subject;

            return subject.asObservable();
        }

        return true;
    }
}