interface NavAttributes {
  [propName: string]: any;
}
interface NavWrapper {
  attributes: NavAttributes;
  element: string;
}
interface NavBadge {
  text: string;
  variant: string;
}
interface NavLabel {
  class?: string;
  variant: string;
}

export interface NavData {
  name?: string;
  url?: string;
  icon?: string;
  badge?: NavBadge;
  title?: boolean;
  children?: NavData[];
  variant?: string;
  attributes?: NavAttributes;
  divider?: boolean;
  class?: string;
  label?: NavLabel;
  wrapper?: NavWrapper;
  roles?: string[];
}

export const navItems: NavData[] = [
  // {
  //   name: 'Dashboard',
  //   url: '/admin/dashboard',
  //   icon: 'icon-speedometer',
  // },
  // { 
  //   name: 'Account',
  //   url: '/admin/account',
  //   icon: 'fa fa-user-circle',
  // },
  // {
  //   name: 'User',
  //   url: ' ',
  //   icon: 'fa fa-user',
  //   children: [
  //     {
  //       name: 'Create',
  //       icon: 'fa fa-plus-circle',
  //       url: '/admin/users/create',
  //       roles: ['Tech Admin']
  //     },
  //     // {
  //     //   name: 'Manage',
  //     //   icon: 'fa fa-ellipsis-h',
  //     //   url: '',
  //     // },
  //     {
  //       name: 'Search',
  //       icon: 'fa fa-search',
  //       url: '/admin/users/search',
  //     }]
  // },
  // {
  //   name: 'Shipment',
  //   icon: 'fa fa-cube',
  //   url: ' ',
  //   roles: ['Tech Admin'],
  //   children: [
  //     {
  //       name: 'Create',
  //       icon: 'fa fa-pencil-square-o',
  //       url: '/admin/shipments/create',
  //       roles: ['Tech Admin']
  //     },
  //     {
  //       name: 'Manage',
  //       icon: 'fa fa-ellipsis-h',
  //       url: '/admin/shipments/manage',
  //       roles: ['Tech Admin']
  //     },
  //     {
  //       name: 'Search',
  //       icon: 'fa fa-search',
  //       url: '/admin/shipments/track',
  //       roles: ['Tech Admin']
  //     }
  //   ]
  // },
  // {
  //   name: 'Customer',
  //   url: ' ',
  //   icon: 'fa fa-handshake-o',
  //   children: [
  //     {
  //       name: 'Create',
  //       icon: 'fa fa-plus-circle',
  //       url: '/admin/customers/create',
  //       roles: ['Tech Admin']
  //     },
  //     // {
  //     //   name: 'Manage',
  //     //   icon: 'fa fa-ellipsis-h',
  //     //   url: '',
  //     // },
  //     {
  //       name: 'Search',
  //       icon: 'fa fa-search',
  //       url: '/admin/customers/search',
  //     }]
  // },
  // {
  //   title: true,
  //   name: 'Manage'
  // },
  {
    name: 'Contractor',
    icon: 'fa fa-truck',
    url: ' ',
    children: [
      {
        name: 'Manage',
        icon: 'fa fa-ellipsis-h',
        url: '/admin/contractors/manage',
      },
      {
        name: 'Search',
        icon: 'fa fa-search',
        url: '/admin/contractors/search',
      }]
  },
  // {
  //   title: true,
  //   name: 'Shipment Activity'
  // },
  // {
  //   name: 'Pickups',
  //   url: '/buttons',
  //   icon: 'icon-cursor',
  //   children: [
  //     {
  //       name: 'Dispatch',
  //       url: '/buttons',
  //       icon: 'icon-cursor',
  //       children: [
  //       ]
  //     },
  //     {
  //       name: 'Pickup Failed',
  //       url: '/buttons',
  //       icon: 'icon-cursor',
  //       children: [
  //       ]
  //     }
  //   ]
  // },
  // {
  //   name: 'In Transit',
  //   url: '/buttons',
  //   icon: 'icon-cursor',
  //   children: [
  //   ]
  // },
  // {
  //   name: 'Deliveries',
  //   url: '/buttons',
  //   icon: 'icon-cursor',
  //   children: [
  //   ]
  // },
  // {
  //   title: true,
  //   name: 'COD'
  // },
  // {
  //   name: 'Search',
  //   url: '/theme/colors',
  //   icon: 'icon-magnifier'
  // },
  // {
  //   name: 'Active',
  //   url: '/theme/colors',
  //   icon: 'icon-clock'
  // },
  // {
  //   name: 'Transfers',
  //   url: '/theme/typography',
  //   icon: 'icon-envelope'
  // }
];
