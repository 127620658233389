import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-email-modal',
  templateUrl: './email-modal.component.html',
  styleUrls: ['./email-modal.component.css']
})
export class EmailModalComponent implements OnInit {
  @Input() title: string;
  @Input() message: string;
  @Input() icon: string;
  @Input() email = '';
  subject: Subject<any>;
  
  constructor(public modalRef: BsModalRef) { }

  ngOnInit(): void {
  }

  action(value: boolean) {
    if (value) {
      this.subject.next({ email: this.email });
    } else
      this.subject.next(null);
    
    this.modalRef.hide();
    this.subject.complete();
  }

  emailValid(): boolean {
    let regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    return regexp.test(this.email);
  }
}
