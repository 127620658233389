import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxMaskModule } from 'ngx-mask';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { WarningModalComponent } from './warning-modal/warning-modal.component';
import { HelpModalComponent } from './help-modal/help-modal.component';
import { CommentModalComponent } from './comment-modal/comment-modal.component';
import { BrowserModule } from '@angular/platform-browser';
import { CropperModalComponent } from './cropper-modal/cropper-modal.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CameraModalComponent } from './camera-modal/camera-modal.component';
import { EmailModalComponent } from './email-modal/email-modal.component';
import { ErrorModalComponent } from './error-modal/error-modal.component';

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        BsDatepickerModule.forRoot(),
        TimepickerModule.forRoot(),
        FontAwesomeModule,
        ImageCropperModule
    ],
    declarations: [
        WarningModalComponent,
        HelpModalComponent,
        CommentModalComponent,
        CropperModalComponent,
        CameraModalComponent,
        EmailModalComponent,
        ErrorModalComponent,
    ],
    exports: [
        WarningModalComponent,
        HelpModalComponent,
        CommentModalComponent,
        CropperModalComponent,
        CameraModalComponent
    ]
})
export class ModalsModule {}