<div class="modal-header">
    <h4 class="modal-title pull-left"><fa-icon *ngIf="icon" class="text-info" [icon]="icon"></fa-icon> {{ title }}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="modal-text">
      <p>{{ message }}</p>
      <div class="row">
        <div class="col-md-8">
          <input type="email"
            class="form-control"
            [(ngModel)]="email"
            [ngClass]="{ 'is-invalid': !emailValid() }"
            autocomplete="no">
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary" [disabled]="!emailValid()" (click)="action(true)">OK</button>
    <button class="btn btn-secondary right" (click)="action(false)">Cancel</button>
  </div>
