import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Reason } from 'src/app/core/reason/reason.model';
import { Note } from 'src/app/core/note/note.model';
import { Location } from 'src/app/core/locations/location-model';

@Component({
  selector: 'app-comment-modal',
  templateUrl: './comment-modal.component.html',
  styleUrls: ['./comment-modal.component.css']
})
export class CommentModalComponent implements OnInit {
  @Input() title: string;
  @Input() icon: string;
  @Input() message: string;
  @Input() dateRequired = false;
  @Input() locations: string[];
  @Input() commentRequired = true;
  @Input() reasons: Reason[];
  @ViewChild('dateInput') dateInput: ElementRef;
  @ViewChild('reasonInput') reasonInput: ElementRef;
  @ViewChild('otherReasonInput') otherReasonInput: ElementRef;
  @ViewChild('commentInput') commentInput: ElementRef;
  subject: Subject<Note>;
  date: Date = null;
  time: Date = null;
  reasonId: number = null;
  otherReason: string = null;
  location: string = null;
  comment: string = null;
  minDate = new Date();
  hasOtherReason = false;

  constructor(public modalRef: BsModalRef) { }

  ngOnInit(): void {
    if (this.reasons.length == 1) {
      this.reasonId = this.reasons[0].id;
      this.hasOtherReason = this.reasons[0].name == 'Other';

      if (this.dateRequired)
        setTimeout(() => {
          this.dateInput.nativeElement.focus();
        }, 200);
      else if (this.hasOtherReason)
        setTimeout(() => {
          this.otherReasonInput.nativeElement.focus();
        }, 200);
        else
        setTimeout(() => {
          this.commentInput.nativeElement.focus();
        }, 200);
    }
  }

  onDateChange(date: Date): void {
    if (date != null) {
      let selectedDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      let currentDate = new Date();
      currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
    }
  }


  action(value: boolean) {
    if (value) {
      let note = new Note(this.reasonId, this.comment, this.date, this.time, this.location);
      this.subject.next(note);
    } else
      this.subject.next(null);
    
    this.modalRef.hide();
    this.subject.complete();
  }

  onReasonChange(): void {
    this.hasOtherReason = this.reasons.find(r => r.id == this.reasonId).name == 'Other';

    if (this.hasOtherReason)
      setTimeout(() => {
        this.otherReasonInput.nativeElement.focus();
      }, 200); 
  }

  isValid(): boolean {
    let valid = true;

    if (this.commentRequired) {
      valid = this.reasonId &&
        this.comment && 
        this.comment.trim().length > 0;

      if (valid && this.hasOtherReason)
        valid = this.otherReason.trim().length > 0;
    }

    if (valid) {
      if (this.dateRequired)
        valid = this.date != null && this.time != null;
    }

    if (valid) {
      if (this.locations)
        valid = this.location != null;
    }

    return valid;
  }
}
