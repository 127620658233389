<div class="modal-header">
    <div class="modal-title pull-left h4"><fa-icon class="text-info" icon="crop"></fa-icon> Crop Image</div>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <div class="row">
        <div class="col-md-12">
            <image-cropper *ngIf="image"
                [imageBase64]="image"
                [maintainAspectRatio]="true" 
                [aspectRatio]="4 / 4"
                (imageCropped)="imageCropped($event)">
            </image-cropper>
        </div>
    </div>
</div>
<div class="modal-footer">
    <button class="btn btn-primary right" (click)="action(true)">
        <fa-icon icon="crop"></fa-icon>
        Crop
    </button>
    <button class="btn btn-secondary" (click)="action(false)">No Crop</button>
</div>
