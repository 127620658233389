import { Deserializable } from 'src/app/core/deserializable/deserializable.model';

export class Lookup implements Deserializable {
    id: number = null;
    entry: string = null;
    value: string = null;

    deserialize(input: any): this {
        return Object.assign(this, input);
    }
}