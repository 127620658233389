<div class="modal-header">
    <h4 class="modal-title pull-left"><fa-icon *ngIf="icon" class="text-info" [icon]="icon"></fa-icon> {{ title }}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="modal-text">
      <p>{{ message }}</p>
    </div>
    <div *ngIf="dateRequired" class="form-row">
      <div class="form-group col-md-12">
        <label>Date <span *ngIf="date == null" style="color:red">*</span></label>
        <input type="text"
            class="form-control"
            #dateInput
            bsDatepicker
            [(ngModel)]="date"
            [minDate]="minDate"
            [bsConfig]="{ isAnimated: true, containerClass: 'theme-default', dateInputFormat: 'MM/DD/YYYY' }"
            (bsValueChange)="onDateChange($event)"
            autocomplete="no">
      </div>
    </div>
    <div *ngIf="dateRequired" class="form-row">
      <div class="form-group col-md-12">
        <label>Time <span *ngIf="time == null" style="color:red">*</span></label>
        <timepicker [(ngModel)]="time"></timepicker>
      </div>
    </div>
    <div *ngIf="locations" class="form-row">
      <div class="form-group col-md-12">
        <label>Location <span *ngIf="location == null" style="color:red">*</span></label>
        <select class="form-control"
          #reasonInput
          [(ngModel)]="location"
          (change)="onReasonChange()">
          <option *ngIf="!location" value="null">Select a location</option>
          <option *ngFor="let location of locations" [value]="location">
              {{ location }}
          </option>
        </select>
      </div>
    </div>
    <div *ngIf="reasons && reasons.length > 1 || reasons.length == 1 && reasons[0].name == 'Other'" class="form-row">
      <div class="form-group col-md-12">
        <label>Reason <span style="color:red">*</span></label>
        <select class="form-control"
          #reasonInput
          [(ngModel)]="reasonId"
          (change)="onReasonChange()">
          <option *ngIf="!reasonId" value="null">Select a reason</option>
          <option *ngFor="let reason of reasons" [value]="reason.id">
              {{ reason.name }}
          </option>
        </select>
      </div>
    </div>
    <div *ngIf="hasOtherReason" class="form-row">
      <div class="form-group col-md-12">
        <label>Other Reason <span style="color:red">*</span></label>
        <input type="text"
          #otherReasonInput
          class="form-control"
          [(ngModel)]="otherReason"
          autocomplete="no">
      </div>
    </div>
    <div class="form-row">
      <div class="form-group col-md-12">
        <label>Comment <span *ngIf="commentRequired" style="color:red">*</span></label>
        <textarea class="form-control" 
          #commentInput
          [(ngModel)]="comment" 
          style="resize: none" 
          rows="3"></textarea>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary" [disabled]="!isValid()" (click)="action(true)">Update</button>
    <button class="btn btn-secondary right" (click)="action(false)">Cancel</button>
  </div>
