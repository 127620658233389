import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { User } from './user.model';
import { UserSearch } from './user-search.model';
import { Lookup } from '../common/models/lookup.model';


@Injectable({
    providedIn: 'root'
})
export class UserService {
    constructor(private http: HttpClient) { }

    get(id: number): Observable<User> {
        let url = `${environment.apiUrl}/Users/Get/${id}`;
        return this.http.get<User>(url).pipe(
            map(data => new User().deserialize(data)));
    }

    getByEmail(email: string): Observable<User[]> {
        let url = `${environment.apiUrl}/Users/GetByEmail/${encodeURI(email)}`;
    
        return this.http.get<User[]>(url).pipe(
            map(data => data.map( d => new User().deserialize(d))));
    }

    getFirstNamesStartsWith(entry: string): Observable<Lookup[]> {
        let url = `${environment.apiUrl}/Users/GetFirstNamesStartsWith/${encodeURI(entry)}`;

        return this.http.get<Lookup[]>(url).pipe(
            map(data => data.map( d => new Lookup().deserialize(d))));
    }

    getLastNamesStartsWith(entry: string): Observable<Lookup[]> {
        let url = `${environment.apiUrl}/Contractors/GetLastNamesStartsWith/${encodeURI(entry)}`;

        return this.http.get<Lookup[]>(url).pipe(
            map(data => data.map( d => new Lookup().deserialize(d))));
    }

    getEmailsStartsWith(entry: string): Observable<Lookup[]> {
        let url = `${environment.apiUrl}/Users/GetEmailsStartsWith/${encodeURI(entry)}`;

        return this.http.get<Lookup[]>(url).pipe(
            map(data => data.map( d => new Lookup().deserialize(d))));
    }

    getByLookup(search: UserSearch): Observable<User[]> {
        let url = `${environment.apiUrl}/Users/GetByLookup`;
        return this.http.post<User[]>(url, search).pipe(
            map(data => data.map( d => new User().deserialize(d))));
    }

    create(user: User): Observable<number> {
        let url = `${environment.apiUrl}/Users/Create`;
        
        return this.http.post<number>(url, user);
    }
}