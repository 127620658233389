import { Reason } from '../reason/reason.model';
import { User } from '../user/user.model';

export class Note {
    id: number = null;
    reasonId: number = null;
    otherReason: string = null;
    date: Date = null;
    location: string = null;
    comment: string = null;
    reason: Reason = null;
    createdBy: number = null;
    createdDate: Date = null;
    user: User = null;

    constructor(reasonId: number, comment: string, date: Date = null, time: Date = null, location: string = null) {
        this.reasonId = reasonId;
        this.comment = comment;
        this.date = date;
        this.location = location;

        if (time && date)
            this.date.setHours(time.getHours(), time.getMinutes(), time.getSeconds());
    }
}