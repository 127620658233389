import { Component, OnInit } from '@angular/core';
import { WebTwain } from 'dwt/WebTwain';
import Dynamsoft from 'dwt';
import { environment } from 'src/environments/environment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs/Subject';

@Component({
  selector: 'app-camera-modal',
  templateUrl: './camera-modal.component.html',
  styleUrls: ['./camera-modal.component.css']
})
export class CameraModalComponent implements OnInit {
  subject: Subject<string>;
  DWObject: WebTwain;
  selectSources: HTMLSelectElement;
  containerId = 'dwtcontrolContainer';
  bWASM = Dynamsoft.Lib.env.bMobile || Dynamsoft.WebTwainEnv.UseLocalService;
  isVideoOn = false;
  editorShown = false;
  image = '';

  constructor(
    public modalRef: BsModalRef) { }

  ngOnInit(): void {
    Dynamsoft.WebTwainEnv.AutoLoad = false;
    Dynamsoft.WebTwainEnv.Containers = [{ WebTwainId: 'dwtObject', ContainerId: this.containerId, Width: '720px', Height: '600px' }];
    Dynamsoft.WebTwainEnv.RegisterEvent('OnWebTwainReady', () => { this.Dynamsoft_OnReady(); });
    Dynamsoft.WebTwainEnv.ProductKey = `${environment.dynamsoft.cameraKey};${environment.dynamsoft.twainKey}`;
    Dynamsoft.WebTwainEnv.ResourcesPath = 'assets/dwt-resources';
    let checkScript = () => {
      if (Dynamsoft.Lib.detect.scriptLoaded) {
        this.modulizeInstallJS();
        Dynamsoft.WebTwainEnv.Load();
      } else {
        setTimeout(() => checkScript(), 100);
      }
    };
    checkScript();
  }

  onClear(): void {
    this.image = '';
    this.DWObject.RemoveAllImages();
    this.SetWebcamPlayVideo();
  }

  Dynamsoft_OnReady(): void {
    this.DWObject = Dynamsoft.WebTwainEnv.GetWebTwain('dwtcontrolContainer');
    this.DWObject.IfShowUI = false;
    this.selectSources = <HTMLSelectElement>document.getElementById("sources");
    this.selectSources.options.length = 0;

    var arySource = this.DWObject.Addon.Webcam.GetSourceList();
    for (var i = 0; i < arySource.length; i++) {
      this.selectSources.options.add(new Option(arySource[i], arySource[i], arySource.length == (i - 1)));
    }

    this.SetWebcamPlayVideo();

    this.selectSources.onchange = () => {
      this.SetWebcamPlayVideo();
    }
  }

  SetWebcamPlayVideo() {
    this.DWObject.Addon.Webcam.SelectSource(this.selectSources.options[this.selectSources.selectedIndex].value);
    this.DWObject.Addon.Webcam.StopVideo();
    this.DWObject.Addon.Webcam.PlayVideo(this.DWObject, 80, function () { });
    this.isVideoOn = true;
  }

  CaptureImage() {
    var funCaptureImage = () => {
      this.DWObject.Addon.Webcam.StopVideo();
      this.isVideoOn = false;
      this.setImage();
    }
    this.DWObject.Addon.Webcam.CaptureImage(funCaptureImage, funCaptureImage);
  }

  modulizeInstallJS() {
    let _DWT_Reconnect = (<any>window).DWT_Reconnect;
    (<any>window).DWT_Reconnect = (...args) => _DWT_Reconnect.call({ Dynamsoft: Dynamsoft }, ...args);
    let __show_install_dialog = (<any>window)._show_install_dialog;
    (<any>window)._show_install_dialog = (...args) => __show_install_dialog.call({ Dynamsoft: Dynamsoft }, ...args);
    let _OnWebTwainOldPluginNotAllowedCallback = (<any>window).OnWebTwainOldPluginNotAllowedCallback;
    (<any>window).OnWebTwainOldPluginNotAllowedCallback = (...args) => _OnWebTwainOldPluginNotAllowedCallback.call({ Dynamsoft: Dynamsoft }, ...args);
    let _OnWebTwainNeedUpgradeCallback = (<any>window).OnWebTwainNeedUpgradeCallback;
    (<any>window).OnWebTwainNeedUpgradeCallback = (...args) => _OnWebTwainNeedUpgradeCallback.call({ Dynamsoft: Dynamsoft }, ...args);
    let _OnWebTwainPreExecuteCallback = (<any>window).OnWebTwainPreExecuteCallback;
    (<any>window).OnWebTwainPreExecuteCallback = (...args) => _OnWebTwainPreExecuteCallback.call({ Dynamsoft: Dynamsoft }, ...args);
    let _OnWebTwainPostExecuteCallback = (<any>window).OnWebTwainPostExecuteCallback;
    (<any>window).OnWebTwainPostExecuteCallback = (...args) => _OnWebTwainPostExecuteCallback.call({ Dynamsoft: Dynamsoft }, ...args);
    let _OnRemoteWebTwainNotFoundCallback = (<any>window).OnRemoteWebTwainNotFoundCallback;
    (<any>window).OnRemoteWebTwainNotFoundCallback = (...args) => _OnRemoteWebTwainNotFoundCallback.call({ Dynamsoft: Dynamsoft }, ...args);
    let _OnRemoteWebTwainNeedUpgradeCallback = (<any>window).OnRemoteWebTwainNeedUpgradeCallback;
    (<any>window).OnRemoteWebTwainNeedUpgradeCallback = (...args) => _OnRemoteWebTwainNeedUpgradeCallback.call({ Dynamsoft: Dynamsoft }, ...args);
    let _OnWebTWAINDllDownloadFailure = (<any>window).OnWebTWAINDllDownloadFailure;
    (<any>window).OnWebTWAINDllDownloadFailure = (...args) => _OnWebTWAINDllDownloadFailure.call({ Dynamsoft: Dynamsoft }, ...args);
  }

  checkIfImagesInBuffer(): boolean {
    if (this.DWObject && this.DWObject.HowManyImagesInBuffer == 0) {
      return false;
    }
    else
      return true;
  }

  setImage(): void {
    const asyncSuccessFunc = (result) => {
      this.image = `data:image/png;base64,${result._content}`;
      this.action(true);
    };

    const asyncFailureFunc = (errorCode, errorString) => {
      console.log("ErrorCode: "+errorCode +"\r"+"ErrorString:"+ errorString);
    }

    let indices: number[] = [];
    for (let i = 0; i <= this.DWObject.CurrentImageIndexInBuffer; i++)
      indices.push(i);

    this.DWObject.ConvertToBase64(indices, 1, asyncSuccessFunc, asyncFailureFunc); 
  }

  action(value: boolean) {
    if (value) {
      this.subject.next(this.image);
    } else
      this.subject.next(null);
    
    this.modalRef.hide();
    this.subject.complete();
  }
}
